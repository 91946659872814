@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-ExtraLight.eot');
    src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'),
        url('../fonts/poppins/Poppins-ExtraLight.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/poppins/Poppins-ExtraLight.woff2') format('woff2'),
        url('../fonts/poppins/Poppins-ExtraLight.woff') format('woff'),
        url('../fonts/poppins/Poppins-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-Medium.eot');
    src: local('Poppins Medium'), local('Poppins-Medium'),
        url('../fonts/poppins/Poppins-Medium.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/poppins/Poppins-Medium.woff2') format('woff2'),
        url('../fonts/poppins/Poppins-Medium.woff') format('woff'),
        url('../fonts/poppins/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-LightItalic.eot');
    src: local('Poppins Light Italic'), local('Poppins-LightItalic'),
        url('../fonts/poppins/Poppins-LightItalic.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/poppins/Poppins-LightItalic.woff2') format('woff2'),
        url('../fonts/poppins/Poppins-LightItalic.woff') format('woff'),
        url('../fonts/poppins/Poppins-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-ExtraLightItalic.eot');
    src: local('Poppins ExtraLight Italic'), local('Poppins-ExtraLightItalic'),
        url('../fonts/poppins/Poppins-ExtraLightItalic.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/poppins/Poppins-ExtraLightItalic.woff2') format('woff2'),
        url('../fonts/poppins/Poppins-ExtraLightItalic.woff') format('woff'),
        url('../fonts/poppins/Poppins-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-Regular.eot');
    src: local('Poppins Regular'), local('Poppins-Regular'),
        url('../fonts/poppins/Poppins-Regular.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/poppins/Poppins-Regular.woff2') format('woff2'),
        url('../fonts/poppins/Poppins-Regular.woff') format('woff'),
        url('../fonts/poppins/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-SemiBold.eot');
    src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
        url('../fonts/poppins/Poppins-SemiBold.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/poppins/Poppins-SemiBold.woff2') format('woff2'),
        url('../fonts/poppins/Poppins-SemiBold.woff') format('woff'),
        url('../fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-Black.eot');
    src: local('Poppins Black'), local('Poppins-Black'),
        url('../fonts/poppins/Poppins-Black.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/poppins/Poppins-Black.woff2') format('woff2'),
        url('../fonts/poppins/Poppins-Black.woff') format('woff'),
        url('../fonts/poppins/Poppins-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-ExtraBoldItalic.eot');
    src: local('Poppins ExtraBold Italic'), local('Poppins-ExtraBoldItalic'),
        url('../fonts/poppins/Poppins-ExtraBoldItalic.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/poppins/Poppins-ExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/poppins/Poppins-ExtraBoldItalic.woff') format('woff'),
        url('../fonts/poppins/Poppins-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-MediumItalic.eot');
    src: local('Poppins Medium Italic'), local('Poppins-MediumItalic'),
        url('../fonts/poppins/Poppins-MediumItalic.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/poppins/Poppins-MediumItalic.woff2') format('woff2'),
        url('../fonts/poppins/Poppins-MediumItalic.woff') format('woff'),
        url('../fonts/poppins/Poppins-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-Light.eot');
    src: local('Poppins Light'), local('Poppins-Light'),
        url('../fonts/poppins/Poppins-Light.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/poppins/Poppins-Light.woff2') format('woff2'),
        url('../fonts/poppins/Poppins-Light.woff') format('woff'),
        url('../fonts/poppins/Poppins-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-Bold.eot');
    src: local('Poppins Bold'), local('Poppins-Bold'),
        url('../fonts/poppins/Poppins-Bold.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/poppins/Poppins-Bold.woff2') format('woff2'),
        url('../fonts/poppins/Poppins-Bold.woff') format('woff'),
        url('../fonts/poppins/Poppins-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-Thin.eot');
    src: local('Poppins Thin'), local('Poppins-Thin'),
        url('../fonts/poppins/Poppins-Thin.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/poppins/Poppins-Thin.woff2') format('woff2'),
        url('../fonts/poppins/Poppins-Thin.woff') format('woff'),
        url('../fonts/poppins/Poppins-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-Italic.eot');
    src: local('Poppins Italic'), local('Poppins-Italic'),
        url('../fonts/poppins/Poppins-Italic.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/poppins/Poppins-Italic.woff2') format('woff2'),
        url('../fonts/poppins/Poppins-Italic.woff') format('woff'),
        url('../fonts/poppins/Poppins-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-ThinItalic.eot');
    src: local('Poppins Thin Italic'), local('Poppins-ThinItalic'),
        url('../fonts/poppins/Poppins-ThinItalic.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/poppins/Poppins-ThinItalic.woff2') format('woff2'),
        url('../fonts/poppins/Poppins-ThinItalic.woff') format('woff'),
        url('../fonts/poppins/Poppins-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-BlackItalic.eot');
    src: local('Poppins Black Italic'), local('Poppins-BlackItalic'),
        url('../fonts/poppins/Poppins-BlackItalic.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/poppins/Poppins-BlackItalic.woff2') format('woff2'),
        url('../fonts/poppins/Poppins-BlackItalic.woff') format('woff'),
        url('../fonts/poppins/Poppins-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-BoldItalic.eot');
    src: local('Poppins Bold Italic'), local('Poppins-BoldItalic'),
        url('../fonts/poppins/Poppins-BoldItalic.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/poppins/Poppins-BoldItalic.woff2') format('woff2'),
        url('../fonts/poppins/Poppins-BoldItalic.woff') format('woff'),
        url('../fonts/poppins/Poppins-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-SemiBoldItalic.eot');
    src: local('Poppins SemiBold Italic'), local('Poppins-SemiBoldItalic'),
        url('../fonts/poppins/Poppins-SemiBoldItalic.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/poppins/Poppins-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/poppins/Poppins-SemiBoldItalic.woff') format('woff'),
        url('../fonts/poppins/Poppins-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-ExtraBold.eot');
    src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'),
        url('../fonts/poppins/Poppins-ExtraBold.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/poppins/Poppins-ExtraBold.woff2') format('woff2'),
        url('../fonts/poppins/Poppins-ExtraBold.woff') format('woff'),
        url('../fonts/poppins/Poppins-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Noto Sans JP';
    font-weight: 700;
    src: local('Noto Sans JP'),
        url('../fonts/noto-serif-jp-v7-latin-regular.woff2') format('woff2'),
        url('../fonts/noto-serif-jp-v7-latin-regular.woff') format('woff');
}

@font-face {
    font-family: 'Noto Sans SC';
    font-weight: 700;
    src: local('Noto Sans SC'),
        url('../fonts/noto-sans-sc-v11-latin-regular.woff2') format('woff2'),
        url('../fonts/noto-sans-sc-v11-latin-regular.woff') format('woff');
}

/* regular noto sans */
@font-face {
    font-family: 'Noto Sans';
    src: local('Noto Sans'),
        url('../fonts/noto-sans-v14-latin-regular.woff2') format('woff2'),
        url('../fonts/noto-sans-v14-latin-regular.woff') format('woff');
}

/* roboto-regular - cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'),
        url('../fonts/roboto-v20-cyrillic-ext_cyrillic-regular.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('../fonts/roboto-v20-cyrillic-ext_cyrillic-regular.woff')
            format('woff'); /* Modern Browsers */
}

@font-face {
    font-family: '나눔스퀘어라운드OTF';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: local('나눔스퀘어라운드OTF '), local('나눔스퀘어라운드OTF'),
        url('../fonts/NanumSquareRoundOTFR.woff2') format('woff2'),
        url('../fonts/NanumSquareRoundOTFR.woff') format('woff');
}

@font-face {
    font-family: 'nanum-square-round-otf';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: local('nanum-square-round-otf '), local('nanum-square-round-otf'),
        url('../fonts/NanumSquareRoundOTFR.woff2') format('woff2'),
        url('../fonts/NanumSquareRoundOTFR.woff') format('woff');
}
