@import './fonts/fonts.css';

body {
    margin: 0;
}

html * {
    font-family: Poppins, 'Roboto', 'Arial', serif;
}
body[lang='vi'],
body[lang='vi'] * {
    font-family: 'Roboto', sans-serif, 'Arial', 'Noto Sans' !important;
}
body[lang='ko'],
body[lang='ko'] * {
    font-family: 'nanum-square-round-otf', 'Arial', 'Roboto', sans-serif !important;
}
body[lang='ru'],
body[lang='ru'] * {
    font-family: 'Roboto', 'Arial', 'Noto Sans', sans-serif !important;
}
body[lang='ja'],
body[lang='ja'] * {
    font-family: 'Noto Sans JP', 'Arial', 'Roboto', sans-serif !important;
}
body[lang='zh_CN'],
body[lang='zh_CN'] * {
    font-family: 'Noto Sans SC', 'Arial', 'Roboto', sans-serif !important;
}
body[lang='ar'],
body[lang='ar'] * {
    font-family: 'Noto Naskh Arabic', 'Scheherazade New', 'Noto Sans Arabic', sans-serif !important;
}
body[lang='he'],
body[lang='he'] * {
    font-family: 'Arial', 'David', sans-serif !important;
}

#root {
    width: 100%;
    height: 100%;
}

iframe {
    display: none !important;
}

#usageTable .MuiDataGrid-cell.MuiDataGrid-withBorderColor,
#assessmentTable .MuiDataGrid-cell.MuiDataGrid-withBorderColor {
    border: none !important;
    outline: none !important;
}
@media screen and (max-width: 959px) {
    #profile-toggle:hover,
    #profile-toggle:active,
    #profile-toggle {
        background: none !important;
    }
}
